<template>
  <div v-if="$place"
       class="popup-audio-guide">
    <div class="popup-audio-guide__wrapper">
      <Column class="grid-md-6 grid-lg-6"
              :full="$mobile">
        <p class="popup-audio-guide__title">Аудиогид по маршруту {{$place.title}}</p>
        <p class="popup-audio-guide__subtitle">Прослушайте аудиогид, который подготовили наши
          путешественники</p>
        <AudioPlayer v-for="audio in $place.audios"
                     class="popup-audio-guide__player"
                     :key="audio.id"
                     :params="audio"/>
      </Column>
    </div>
  </div>
  <Loader v-else/>
</template>

<script>
export default {
  name: 'PopupAudioGuide',
};
</script>
